import apiClient from '@/services/axios'

export const getDashboardSiteManager = async (id, date, search) => {
  const res = await apiClient.get(`/siteManager/status?id=${id}`, { params: { date, search } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
